html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: Spantaran;
  src: url("Spantaran.719acbc4.ttf");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  color: #fffeee;
  background-color: #000;
  font-family: Bitter, serif;
}

figure, section {
  margin: 0;
  padding: 0;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

h2 {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #eee;
  font-family: Spantaran;
}

p {
  line-height: 1.8em;
}

#header {
  height: 100vh;
  background-image: url("forest.ae814ef9.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}

#header h1 {
  text-indent: -100000px;
  height: 85px;
  width: 190px;
  background-image: url("logo.8c1ce3dd.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 320px) {
  h2 {
    font-size: 2em;
  }

  #header nav ul.social {
    display: none;
  }

  #header {
    padding-top: 2em;
  }

  #header h1 {
    margin: 0 auto;
  }

  #header ul {
    justify-content: center;
  }

  #header li {
    padding: 1em .5em 0;
  }
}

@media screen and (min-width: 1000px) {
  h2 {
    font-size: 3em;
  }

  #header {
    padding-top: 1.5em;
  }

  #header nav {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  #header nav ul.social {
    display: inherit;
  }

  #header nav :nth-child(1) {
    order: 2;
  }

  #header nav :nth-child(2) {
    order: 1;
  }

  #header nav :nth-child(3) {
    order: 3;
  }

  #header ul :nth-child(1) {
    order: 1;
  }

  #header ul :nth-child(2) {
    order: 2;
  }

  #header ul :nth-child(3) {
    order: 3;
  }

  #header ul :nth-child(4) {
    order: 4;
  }

  #header ul {
    width: 33vw;
    justify-content: left;
  }

  #header li {
    padding: 1em 1em 0;
  }
}

#header nav .social img {
  height: 1.7rem;
}

#header nav .social .x img {
  height: 1.3rem;
  position: relative;
  top: 3px;
}

#header ul {
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1em;
  list-style: none;
  display: flex;
}

#header ul.social {
  justify-content: right;
}

#header li {
  margin: 0;
}

#header a {
  color: #fff;
  opacity: .9;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: Spantaran;
  font-size: 1.2rem;
  text-decoration: none;
}

#header a:hover {
  opacity: 1;
}

#header .arrow {
  width: 100vw;
  text-align: center;
  position: absolute;
  bottom: 0;
}

#header .arrow img {
  width: 6em;
}

#header #mint a {
  font-family: Bitter, serif;
}

#mint {
  text-align: center;
  width: 20em;
  color: #ccc;
  margin-left: -10em;
  position: absolute;
  top: 90%;
  left: 50%;
}

@media screen and (min-width: 320px) {
  #mint {
    height: 12em;
    margin-top: -10em;
  }
}

@media screen and (min-width: 1200px) {
  #mint {
    height: 20em;
    margin-top: -15em;
  }
}

#mint #connect {
  color: #fff;
  text-transform: uppercase;
  opacity: .85;
  letter-spacing: 1px;
  border: 2px solid #fff;
  border-radius: 15px;
  padding: 1em 1.5em;
  font-size: 1em;
  font-weight: 600;
  text-decoration: none;
}

#mint #connect.disabled {
  cursor: not-allowed;
  opacity: .5;
}

#mint p {
  padding-top: 1em;
  font-size: .9em;
}

#mint em {
  padding: 0 4px;
  font-size: 1.2em;
}

#mint #welcome {
  padding-bottom: 1em;
}

@media screen and (min-width: 320px) {
  #about {
    background: linear-gradient(185deg, #13131b 95%, #1b1e27 95%);
    padding: 5em 5vw;
  }

  #about .hey img {
    max-width: 60vw;
  }

  #about .hey {
    text-align: center;
  }

  #about p.social {
    text-align: center;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1200px) {
  #about {
    background: linear-gradient(185deg, #13131b 85%, #1b1e27 85%);
    align-items: center;
    padding: 5em 5vw 15em;
    display: flex;
  }

  #about .hey img {
    max-width: 30vw;
  }

  #about p.social {
    text-align: left;
  }
}

#about {
  max-width: 90vw;
}

#about h1 {
  margin-bottom: 2em;
}

#about p {
  padding-bottom: 2em;
  font-size: 1.1em;
}

#about a:hover {
  opacity: 1;
}

#about .social img {
  max-width: 40px;
}

#about .social .x img {
  max-width: 30px;
  position: relative;
  top: -5px;
}

#about .social a {
  opacity: .8;
  margin-right: 1em;
}

#about .social a:hover {
  opacity: 1;
}

@media screen and (min-width: 320px) {
  #genesis {
    background: linear-gradient(-3deg, #252833 1%, #1b1e27 1%);
    padding: .1em 5vw 10em;
  }

  #genesis section {
    display: block;
  }

  #genesis h2 {
    text-align: left;
  }

  .card {
    width: 90vw;
    height: 90vw;
    margin: 0 0 1vw;
  }

  .card img {
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  #genesis {
    background: linear-gradient(-3deg, #252833 10%, #1b1e27 10%);
    padding: .1em 5vw 15em;
  }

  #genesis section {
    grid-template-columns: repeat(auto-fit, minmax(17vw, 1fr));
    display: grid;
  }

  #genesis h2 {
    text-align: center;
  }

  .card {
    width: 17vw;
    height: 17vw;
    margin: 0 0 1vw;
  }

  .card img {
    width: 17vw;
  }

  .card p {
    font-size: .8em;
  }
}

#genesis {
  max-width: 90vw;
}

#genesis > p {
  padding-bottom: 2em;
  font-size: 1.1em;
}

.card {
  perspective: 1000px;
  text-align: left;
  background-color: #0000;
}

.card .inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform .8s;
  position: relative;
}

.card:hover .inner {
  transform: rotateY(180deg);
}

.card .front, .card .back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
}

.card .front {
  background-color: #bbb;
}

.card .back {
  color: #fff;
  background-color: #252733;
  background-image: url("bg-pattern.305f6759.png");
  overflow: scroll;
  transform: rotateY(180deg);
}

.card p, .card h3 {
  padding: 0 1em;
}

.card p {
  opacity: .8;
}

.card h3 {
  letter-spacing: 1px;
  padding-left: .7em;
  font-family: Bitter, serif;
  font-weight: normal;
}

@media screen and (min-width: 320px) {
  #main {
    max-width: 90vw;
    padding: 2em 5vw 15em;
  }

  #main #preview {
    width: 90vw;
    height: 90vw;
  }

  #main h2 {
    text-align: left;
  }

  #main h3 {
    font-size: 1.6em;
  }
}

@media screen and (min-width: 900px) {
  #main {
    max-width: 60vw;
    padding: 5em 20vw 15em;
  }

  #main div {
    flex-direction: row-reverse;
    place-content: center space-between;
    display: flex;
  }

  #main figure {
    width: 30vw;
  }

  #main #preview {
    width: 25vw;
    height: 25vw;
  }

  #main h2 {
    text-align: center;
  }

  #main h3 {
    font-size: 2em;
  }
}

#main {
  text-align: center;
  background: linear-gradient(2deg, #2f313f 10%, #252833 10%);
}

#main h3 {
  text-align: left;
  letter-spacing: 1px;
  font-weight: normal;
}

#main #preview {
  background: #2f313f;
  border: 3px solid #000;
  border-radius: 10px;
  margin-top: 2em;
  position: relative;
}

#main #preview img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#main p {
  text-align: left;
}

@media screen and (min-width: 320px) {
  #roadmap {
    max-width: 90vw;
    background: linear-gradient(-3deg, #393d4c 1%, #2f313f 1%);
    padding: .1em 5vw 5em;
  }

  #roadmap img {
    max-width: 50vw;
  }

  #roadmap figure {
    text-align: center;
  }

  #roadmap figure p, #roadmap figure h3 {
    text-align: left;
  }
}

@media screen and (min-width: 1000px) {
  #roadmap {
    max-width: 60vw;
    background: linear-gradient(-3deg, #393d4c 5%, #2f313f 5%);
    padding: 5em 20vw 15em;
  }

  #roadmap figure {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  #roadmap div {
    max-width: 25vw;
  }

  #roadmap figure {
    text-align: left;
  }

  #roadmap figure.even {
    text-align: right;
    flex-direction: row-reverse;
  }

  #roadmap figure.even p, #roadmap figure.even h3 {
    text-align: right;
  }

  #roadmap img {
    max-width: 25vw;
  }
}

#roadmap {
  text-align: center;
}

#roadmap figure {
  padding-bottom: 3em;
}

#roadmap h3.phase {
  font-style: italic;
  position: relative;
  top: -30px;
}

#roadmap h3 {
  letter-spacing: 1px;
  margin-bottom: 0;
  font-family: Bitter, serif;
  font-size: 1.5em;
  font-weight: normal;
}

@keyframes floating {
  0% {
    transform: translate(0);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0);
  }
}

#roadmap img {
  animation-name: floating;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

#roadmap .even img {
  animation-duration: 5s;
}

#roadmap p {
  opacity: .8;
  font-size: 1em;
}

@media screen and (min-width: 320px) {
  #team {
    max-width: 90vw;
    background: linear-gradient(2deg, #424759 1%, #393d4c 1%);
    padding: 5em 5vw;
  }
}

@media screen and (min-width: 900px) {
  #team {
    max-width: 80vw;
    background: linear-gradient(2deg, #424759 10%, #393d4c 10%);
    padding: .1em 10vw 5em;
  }

  #team ul {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 auto;
    display: flex;
  }

  #team li {
    width: 17.5vw;
  }
}

#team {
  text-align: center;
}

#team ul {
  padding: 3em 0 0;
  list-style: none;
}

#team li {
  text-align: center;
  padding-bottom: 3em;
}

#team figure {
  margin: 0;
  padding: 0;
}

#team h3 {
  letter-spacing: 1px;
  font-family: Bitter, serif;
  font-size: 1.5em;
  font-weight: normal;
}

#team em {
  margin-top: -1em;
  font-family: Bitter, serif;
  font-size: 1em;
  font-weight: normal;
  display: block;
}

#team .member img {
  width: 200px;
  clip-path: circle();
}

#team .member a img {
  width: 20px;
  opacity: .8;
}

#team .member a:hover img {
  opacity: 1;
}

#team .dev h3 {
  font-family: Courier New, Courier, monospace;
  font-weight: normal;
}

@media screen and (min-width: 320px) {
  #faq {
    max-width: 90vw;
    text-align: center;
    padding: 5em 5vw 10em;
  }

  #faq img {
    max-width: 70vw;
    max-height: 70vw;
    padding-top: 5em;
  }

  #faq figure, #faq h2 {
    text-align: left;
  }
}

@media screen and (min-width: 900px) {
  #faq {
    max-width: 70vw;
    text-align: left;
    flex-direction: row-reverse;
    padding: 5em 15vw 10em;
    display: flex;
  }

  #faq > div {
    width: 45vw;
  }

  #faq img {
    max-width: 25vw;
    max-height: 25vw;
    padding-top: 10em;
  }
}

#faq {
  background: #424759;
}

#faq h3 {
  letter-spacing: 1px;
  font-size: 1.2em;
  font-weight: normal;
  position: relative;
  top: 5px;
}

#faq p {
  opacity: .8;
  margin-top: 0;
  font-size: 1em;
  display: none;
}

#faq .open p {
  display: block;
}

#faq figure div {
  border-top: 1px solid #2f313f;
  justify-content: space-between;
  display: flex;
}

#faq figure span {
  padding: 1.5em 0;
}

#faq .faq-item {
  cursor: pointer;
  margin: 0;
  padding: 0;
}

#faq a {
  color: #fffeee;
}

@media screen and (min-width: 320px) {
  footer {
    text-align: center;
  }
}

@media screen and (min-width: 640px) {
  footer {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

footer {
  background-color: #424759;
  padding: .1em 2em .5em;
}

footer p {
  color: #fff;
  opacity: .7;
  padding-top: .2em;
  font-size: .8em;
}

footer img {
  height: 1.7rem;
}

footer .x img {
  height: 1.3rem;
  position: relative;
  top: 3px;
}

footer ul {
  justify-content: center;
  padding: 0;
  list-style: none;
  display: flex;
}

footer li {
  padding: 0 1em;
}

footer a img {
  opacity: .8;
}

footer a:hover img {
  opacity: 1;
}

footer a {
  color: #fff;
}

#terms p {
  font-size: 1em;
}

#terms #logo {
  margin-bottom: 4em;
  display: block;
}

#terms #logo img {
  width: 220px;
}

#terms h1 {
  font-size: 2em;
}

#terms footer {
  background: #000;
  font-size: .8em;
}

#disclaimer h2 {
  margin-top: 2em;
  font-family: Bitter, serif;
  font-size: 1.6em;
}

#disclaimer {
  max-width: 80vw;
  padding: 5em 10vw;
}

/*# sourceMappingURL=index.f5e8ca0a.css.map */
